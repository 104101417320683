//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    showEmoji: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      emojiList: this.$constant.emojiList,
      emojiListURL: {}
    };
  },
  created: function created() {
    this.emojiListURL = this.getEmojiList(this.emojiList);
  },
  methods: {
    addEmoji: function addEmoji(key) {
      this.$emit("addEmoji", key);
    },
    getEmojiList: function getEmojiList(emojiList) {
      var emojiName;
      var url;
      var result = {};

      for (var i = 0; i < emojiList.length; i++) {
        emojiName = "[" + emojiList[i] + "]";
        var j = i + 1;
        url = this.$constant.qiniuDownload + "emoji/q" + j + ".gif";
        result[emojiName] = url;
      }

      return result;
    }
  }
};